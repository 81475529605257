// Import necessary libraries or components
import "../styles/flicker.css";

// const { tracks, loadTrack } = Astro.props;
// Create a functional component TracklistMenu
const TracklistMenu = ({ tracks, loadTrack, currentTrack }) => {
  return (
    <div className="tracklist-container text-vivid_sky_blue-500 w-full h-60 mx-auto mt-0 mb-0 font-sans rounded-md bg-dim_gray-500 overflow-scroll">
      <div className="playlist border-dim_gray-400">
        {tracks?.map((track) => (
          <div
            key={track.s3Key}
            className={`tracklist-item flex justify-between items-center p-2 cursor-pointer border-b border-dim_gray-300 ${
              currentTrack === track
                ? "bg-vivid_sky_blue-500 text-dim_gray-100"
                : "hover:bg-dim_gray-300"
            }`}
            onClick={() => loadTrack(track)}
          >
            <span className="crt">{track.title}</span>
            <span className="text-sm">{track.duration}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TracklistMenu;
