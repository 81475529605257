export const styles = {
  mediaControls: {
    alignItems: "center",
    background: "#fff6cc",
    // border: "1px solid #ffedcc",
    borderRadius: "1.5rem",
    color: "#666666",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "auto",
    minWidth: "calc(100% - 1rem)",
    maxWidth: "calc(100% - 4rem)",
    padding: "2rem",
    position: "relative",
  },
  mediaButtons: {
    display: "flex",
    flexWrap: "nowrap",
    marginTop: "2rem",
    alignItems: "center",
  },
  mediaButton: {
    backgroundColor: "transparent",
    border: "none",
    alignItems: "center",
    borderRadius: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    padding: "0.5rem",
  },
  buttonIcons: {
    display: "block",
    backgroundColor: "rgba(255, 133, 27, 0.4)",
    borderRadius: "100%",
    marginBottom: "0.125rem",
    marginTop: "auto",
    padding: "1rem",
  },
  backAndSkipButtonIcons: {
    backgroundColor: "transparent",
    color: "rgba(85, 85, 85, 0.72)",
  },
  rewindAndForwardButtonIcons: {
    background:
      "linear-gradient(to bottom left, #fff8e1, rgba(255, 133, 27, 0.2))",
    color: "rgba(85, 85, 85, 0.81)",
  },
  playButtonIcons: {
    background: "linear-gradient(to bottom left, #ffffff, #fff8e1)",
    border: "1px solid #ffedcc",
    boxShadow:
      "-1px 1px 1px rgba(255, 133, 27, 0.25), 1px -1px 1px rgba(255, 255, 255, 0.25), -2px 2px 2px rgba(255, 133, 27, 0.2), 2px -2px 2px rgba(255, 255, 255, 0.2), -4px 4px 4px rgba(255, 133, 27, 0.15), 4px -4px 4px rgba(255, 255, 255, 0.15), -8px 8px 8px rgba(255, 133, 27, 0.1), 8px -8px 8px rgba(255, 255, 255, 0.1), -16px 16px 16px rgba(255, 133, 27, 0.05), 16px -16px 16px rgba(255, 255, 255, 0.05)",
    color: "#e56c00",
    marginBottom: "0.25rem",
    padding: "2rem",
    width: "1.2rem", // Add this line
    height: "1.2rem",
  },
  mediaProgress: {
    alignSelf: "stretch",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  progressBarWrapper: {
    backgroundColor: "transparent",
    borderRadius: "0.5rem",
    boxShadow:
      "inset -1px 1px 1px rgba(255, 133, 27, 0.25), inset 1px -1px 1px rgba(255, 255, 255, 0.25), inset -2px 2px 2px rgba(255, 133, 27, 0.2), inset 2px -2px 2px rgba(255, 255, 255, 0.2), inset -4px 4px 4px rgba(255, 133, 27, 0.15), inset 4px -4px 4px rgba(255, 255, 255, 0.15), inset -8px 8px 8px rgba(255, 133, 27, 0.1), inset 8px -8px 8px rgba(255, 255, 255, 0.1), inset -16px 16px 16px rgba(255, 133, 27, 0.05), inset 16px -16px 16px rgba(255, 255, 255, 0.05)",
    height: "0.5rem",
    marginBottom: "0.25rem",
    marginTop: "2rem",
    position: "relative",
    width: "100%",
  },
  progressBar: {
    background: "linear-gradient(to right, #FFD0A8, #FCAB64)",
    borderRadius: "0.5rem",
    boxShadow: "none",
    height: "0.5rem",
    width: "0%",
  },
  audioPlayer: {
    width: "100%",
    marginTop: "1rem",
    backgroundColor: "#ffedcc",
    borderRadius: "1.5rem",
    padding: "0.5rem",
  },
};