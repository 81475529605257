import React, { useRef, useState, useEffect } from "react";
import { styles } from "../styles/styles.js";
import TracklistMenu from "./TracklistMenu.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward, faPause, faPlay, faStepBackward, faStepForward,  } from "@fortawesome/free-solid-svg-icons";
import { formatTime } from "../util/time.ts";

const AudioPlayer = ({ tracks }) => {
  const audioRef = useRef(null);
  const progressBarRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hasIncrementedPlayCount, setHasIncrementedPlayCount] = useState(false);
  const threshold = 10; // Threshold in seconds before the end of the audio

  const handlePlay = () => {
    // if no current track, set the first track in the list
    if (!currentTrack) {
      setCurrentTrack(tracks[0]);
      return
    }
    setPlaying(true);
    audioRef.current.play();
  };

  const handlePause = () => {
    setPlaying(false);
    audioRef.current.pause();
  };

  const handleRewind = () => {
    audioRef.current.currentTime -= 10; // Rewind by 10 seconds
  };

  const handleForward = () => {
    audioRef.current.currentTime += 10; // Forward by 10 seconds
  };

  // Add a handleSkipBackward to skip to the previous track
  const handleSkipBackward = () => {
    const currentIndex = tracks.findIndex((track) => track === currentTrack);
    const nextIndex = currentIndex === 0 ? tracks.length - 1 : currentIndex - 1;
    setCurrentTrack(tracks[nextIndex]);
  };

  // Add a handleSkipForward to skip to the next track
  const handleSkipForward = () => {
    const currentIndex = tracks.findIndex((track) => track === currentTrack);
    const nextIndex = currentIndex === tracks.length - 1 ? 0 : currentIndex + 1;
    setCurrentTrack(tracks[nextIndex]);
  };

  const handleTimeUpdate = async () => {
    const currentTime = audioRef.current.currentTime;
    setCurrentTime(formatTime(currentTime));
    const duration = audioRef.current.duration;
    const progress = (currentTime / duration) * 100;
    progressBarRef.current.style.width = `${progress}%`;
    // Check if the audio is within the last 10 seconds and play count hasn't been incremented
    if (duration - currentTime <= threshold && !hasIncrementedPlayCount) {
      // Increment play count here
      console.log("Incrementing play count"); // Replace this with your actual increment function
      await fetch("/api/incrementPlayCount", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ track: currentTrack }),
          });
      setHasIncrementedPlayCount(true);
    }
  };

  useEffect(() => {
    const fetchAndPlayTrack = async () => {
      if (currentTrack && currentTrack.s3Key) {
        try {
          // Assuming getS3SignedUrl is a function that fetches a signed URL for the track
          audioRef.current.src = currentTrack.url;
          await new Promise((resolve, reject) => {
            audioRef.current.onloadedmetadata = () => {
              resolve();
            };
            audioRef.current.onerror = () => {
              reject(new Error("Failed to load audio metadata."));
            };
            audioRef.current.onended = handleSkipForward;
            audioRef.current.load();
          });
          const duration = await audioRef.current.duration;
          setDuration(formatTime(duration));
          setPlaying(true);
          audioRef.current.play();
        } catch (error) {
          console.error("Error fetching or playing track:", error);
        }
      }
    };
    fetchAndPlayTrack();
  }, [currentTrack]);

  // useEffect to rerender when playing state changes
  useEffect(() => {
    if (playing) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [playing]);

  return (
    <div style={styles.mediaControls}>
      <TracklistMenu
        tracks={tracks}
        loadTrack={setCurrentTrack}
        currentTrack={currentTrack}
      />
      <div style={styles.mediaButtons}>
        <button style={styles.mediaButton} onClick={handleSkipBackward}>
          <FontAwesomeIcon
            icon={faStepBackward}
            style={{ ...styles.buttonIcons, ...styles.backAndSkipButtonIcons }}
          />
          <span className="button-text milli"></span>
        </button>

        <button style={styles.mediaButton} onClick={handleRewind}>
          <FontAwesomeIcon
            icon={faBackward}
            style={{
              ...styles.buttonIcons,
              ...styles.rewindAndForwardButtonIcons,
            }}
          />
          <span className="button-text milli"></span>
        </button>

        <button
          style={styles.mediaButton}
          onClick={!playing ? handlePlay : handlePause}
        >
          <FontAwesomeIcon
            icon={playing ? faPause : faPlay}
            style={{...styles.playButtonIcons, ...styles.buttonIcons}}
          />
          <span className="button-text milli"></span>
        </button>

        <button style={styles.mediaButton} onClick={handleForward}>
          <FontAwesomeIcon
            icon={faForward}
            style={{
              ...styles.buttonIcons,
              ...styles.rewindAndForwardButtonIcons,
            }}
          />
          <span className="button-text milli"></span>
        </button>

        <button style={styles.mediaButton} onClick={handleSkipForward}>
          <FontAwesomeIcon
            icon={faStepForward}
            style={{ ...styles.buttonIcons, ...styles.backAndSkipButtonIcons }}
          />
          <span className="button-text milli"></span>
        </button>
      </div>
      <div style={styles.mediaProgress}>
        <div
          style={styles.progressBarWrapper}
          // onClick={(e) => {
          //   const progressBarWidth = progressBarRef.current.offsetWidth;
          //   console.log("progressBarWidth:", progressBarWidth);
          //   const clickPosition = e.nativeEvent.offsetX;
          //   console.log("clickPosition:", clickPosition);
          //   const duration = audioRef.current.duration;
          //   const newCurrentTime = (clickPosition / progressBarWidth) * duration;
          //   console.log("Seeking to:", newCurrentTime);
          //   audioRef.current.currentTime = newCurrentTime;
          // }}
        >
          <div ref={progressBarRef} style={styles.progressBar}></div>
        </div>
        <div className="progress-time-current milli">
          {currentTime || "🎧🎧"}
        </div>
        <div className="progress-time-total milli">{duration || "🎧🎧"}</div>
      </div>
      <audio
        ref={audioRef}
        id="audio-player"
        style={styles.audioPlayer}
        onTimeUpdate={handleTimeUpdate}
      >
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
